import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
const Container = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: props.theme.palette.primary.main,
}));
const ActiveTextButton = ({ children = undefined, 'data-testid': dataTestId = undefined, onClick = () => { }, }) => (_jsx(Container, { "data-testid": dataTestId, onClick: onClick, children: children }));
export default ActiveTextButton;
